import { renderToStaticMarkup } from 'react-dom/server'
import html2pdf from 'html-to-pdf-js'
import { List } from '../common'
import './app.style.scss'
import { useState } from 'react'

const generatePDF = async (jsxNode, onFinished) => {
  const htmlString = renderToStaticMarkup(jsxNode)
  const options = {
    margin: [0, 10, 0, 10], // top, left, bottom, right
    filename: `rcbhuls_saccade_cv_${Date.now()}.pdf`,
    image: { type: 'jpeg', quality: 1.00 },
    html2canvas: { scale: 2, windowWidth: 1024 }, // window width so that even on mobile, the same width dimensions are used to generate the pdf
    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait', userUnit: 1.6 },

    pagebreak: { mode: ['css'] } // Looks for pageBreak: true on <List/> item (break-after/break-before css property)
  }
  await html2pdf().set(options).from(htmlString).outputPdf().save()

  /** Note: opening in a new tab on iOS remains problematic with this pdf library */
  // let pdf

  // // Trick to open pdf in new browser tab
  // try {
  //   pdf = await html2pdf().set(options).from(htmlString).toPdf().get('pdf')
  //   const blob = await (await fetch(pdf.output('datauristring'))).blob() // We create our own blob so we have more control over mime types and such

  //   //const blob = new Blob([pdf.output('datauristring')], { type: 'application/pdf' });
  //   const file = URL.createObjectURL(blob)
  //   window.open(file, '_blank')
  // } catch (err) {
  //   console.warn('Oops! An error occurred while attempting to generate a PDF;', err)
  // }

  if (typeof onFinished === 'function') onFinished()
}

const CV = ({ includePersonalia }) => {
  return (
    <>
      {/* PERSONALIA */}
      {includePersonalia ? 
        <div className='__CV_PDF__'>
          <header>
            <img className='profile-img' src='images/img_randy_arcade.png' alt='Randy Huls'/>
          </header>

          <List
            pageBreak
            label='Personal'
            items={[
              {
                children: (
                  <div className='table-list'>
                    <ul>
                      <li>Full name</li>
                      <li>Place of birth</li>
                      <li>Date of birth</li>
                      <li>E-mail address</li>
                      <li>Phone number</li>
                      <li>Website</li>
                      <li>LinkedIn</li>
                    </ul>

                    <ul>
                      <li>Randy 'Chikwado Ben' Huls</li>
                      <li>Utrecht, The Netherlands</li>
                      <li>April 19th, 1989</li>
                      <li>randy@saccade.io</li>
                      <li>+31 (0)6 15 375 300</li>
                      <li>saccade.io</li>
                      <li>linkedin.com/in/RandyHuls</li>
                    </ul>
                  </div>
                )
              }
            ]}
          />
        </div>
      : null}

      {/* WORK EXPERIENCE */}
      <List
        pageBreak
        label='Work Experience'
        items={[
          {
            label: 'SDB Groep - Frontend development (ReactJS)',
            text: 'At SDB Groep I use a React tech stack including: TypeScript, Redux, MUI, and Yup among others.',
            fromDate: new Date('07/03/2023')
          },
          {
            label: 'Saccade – Frontend developer (contracter)',
            text: 'Saccade is the name of my company, under which I have performed both development and design work for various clients including: The Institute of Creativity, Hogeschool van Amsterdam, Fjuze, CVandaag.nl, and NieuwRechts.nl.',
            fromDate: new Date('11/30/2018')
          },
          {
            label: 'TIOC.nl - Front- and Backend development (NextJS/Strapi)',
            text: 'I did front- and backend development for this project. Strapi was the backend mule, with NextJS covering the frontend of the project. The web app was then Dockerized, with updates relying on a pipeline between GitHub Actions and a custom VPS.',
            fromDate: new Date('06/11/2022'),
            toDate: new Date('03/31/2023')
          },
          {
            label: 'KPN / Fjuze - The Digital Dutch 2022 - Mobile app development (React Native) [February-April 2022]',
            text: 'Hired by Fjuze for a two-month assignment, I built an experimental mobile app for KPN\'s Digital Dutch 2022 using React Native and web sockets. Using this \'multiscreen\' app, smartphones could be connected into a grid to project a larger screen.',
            fromDate: new Date('01/02/2022'),
            toDate: new Date('04/19/2022')
          },
          {
            label: 'Hogeschool van Amsterdam – Front- and Backend development teacher',
            text: 'For the course Communication & Multimedia Design I taught both first- and third year students. First year students were taught all the basics of frontend development, covering JavaScript and advanced HTML/CSS. Third year students were taught backend development, where they grappled with NodeJS, Express and package management.',
            fromDate: new Date('02/03/2020'),
            toDate: new Date('07/31/2022')
          },
          {
            label: 'Blaud – Frontend & Mobile development (Angular / Ionic)',
            text: 'At Blaud I focussed heavily on mobile development, though often through hybrid frameworks. My stack contained Angular, Cordova, TypeScript and Swift. Considering my background in design, I was often responsible for providing the UI design as well. Clients included: RijkZwaan, Netherlands Labour Authority, Ministry of Defense and ING.',
            fromDate: new Date('09/01/2016'),
            toDate: new Date('11/30/2018')
          },
          {
            label: 'De Crime Compagnie – Frontend & Mobile development (thesis internship)',
            text: 'At "De Crime Compagnie" I spent five months building an interactive book--the smartbook--to inspire young folks into reading. Initially a web app, then turned into an iOS and Android app using Cordova',
            fromDate: new Date('09/01/2016'),
            toDate: new Date('07/31/2016'),
          }
        ]}
      />

      {/* EDUCATION */}
      <List
        label='Education'
        items={[
          {
            label: 'Hogeschool van Amsterdam (Amsterdam University of Applied Sciences) – Bsc. of Science',
            text: 'Majored in Communication & Multimedia Design, with a minor in User Experience Design. During my study I was taught both human centered design principles and frontend development, with a small sidestep to backend development.',
            fromDate: new Date('09/01/2012'),
            toDate: new Date('07/31/2016')
          },
          {
            label: 'Grafisch Lyceum Utrecht – MBO4',
            text: 'Studied Multimedia Design; dove into all the familiar Adobe software and applying it to both webdesign and classic print media.',
            fromDate: new Date('09/01/2006'),
            toDate: new Date('07/31/2010')
          },
          {
            label: 'Cals College IJsselstein – TL+',
            text: 'High School. Good times.',
            fromDate: new Date('09/01/2001'),
            toDate: new Date('07/31/2006')
          }
        ]}
      />

      {/*  STACK & SKILLS */}
      <div className='half-columns'>
        <List
          pageBreak
          label='Stack (current)'
          items={[
            {
              label: 'Web development',
              text: 'TypeScript, NextJS, ReactJS, SCSS (CSS modules / Styled components), GraphQL, PostgreSQL, Strapi, Docker, NodeJS/Express, EJS.',
            },
            {
              label: 'Mobile development',
              text: 'React Native, Swift',
            },
            {
              label: 'DevOps',
              text: 'Docker, GitHub Actions, GitHub Packages',
            }
          ]}
        />

        <List
          label='Skills'
          items={[
            {
              label: 'Development',
              text: 'Front- and backend development, mobile development, bash scripting, dev ops (though rudimentary).',
            },
            {
              label: 'Design',
              text: 'Grounded user experience- and interaction design skills. Pretty solid with visual design. Confident in the big software packages (Adobe stuff, Sketch 3)',
            }
          ]}
        />
      </div>
    </>
  )
}

const App = () => {
  const [generatingPDF, setGeneratingPDF] = useState(false)

  return (
    <div className='__COMPONENT__app'>
      <div className='container'>
        <div className='content-container'>
          <div className='column left'>
            <img className='profile-img' src='images/img_randy_arcade.png' alt='Randy Huls' draggable={false}/>
            <h2>Randy Huls</h2>
            <p>"If i’m not programming, I’m probably thinking about programming."</p>

            <button onClick={() => { window.location.href = 'mailto:randy@saccade.io' }}>Send an e-mail</button>
            <button disabled={generatingPDF} onClick={async () => {
              setGeneratingPDF(true)
              try {
                await generatePDF(<CV includePersonalia/>, () => setGeneratingPDF(false))
              } catch (err) {
                setGeneratingPDF(false)
              }
            }}>{generatingPDF ? 'Generating pdf...' : 'Download resume (.pdf)'}</button>
            <button>
              <a href='https://www.behance.net/randyhuls' target='_blank' rel='nofollow noreferrer'>See my design work @ Behance</a>
            </button>

            <div className='social-media-container'>
              <p>Or snoop through my various social media pages</p>

              <div className='icons'>
                <a href='https://linkedin.com/in/randyhuls' target='_blank' rel='nofollow noreferrer'><img src='images/icon_linkedin_white.svg' alt='LinkedIn icon white svg'/></a>
                {/* <a href='https://facebook.com/randyhuls' target='_blank' rel='nofollow noreferrer'><img src='images/icon_facebook_white.svg' alt='Facebook icon white svg'/></a> */}
                <a href='https://github.com/randyhuls' target='_blank' rel='nofollow noreferrer'><img src='images/icon_github_white.svg' alt='GitHub icon white svg'/></a>
              </div>
            </div>

            <hr/>
          </div>

          <div className='column right'>
            <header>
              <h1>Development and design with a dose of <a href='#fun-stuff'>humor</a>.</h1>
            </header>
            
            <CV/>

            {/* FUN STUFF */}
            <List
              label='Fun stuff'
              items={[
                {
                  text: 'Development (walked right into that one), electrical engineering (fixing up old game consoles), working out, reading, writing, gaming.',
                },
                {
                  children: (
                    <>
                      <span>Some fun stuff I've been working on:</span>
                      <a href='https://apps.apple.com/nl/app/reach-the-star-asmr-game/id1455214835?platform=iphone' target='_blank' rel='nofollow noreferrer'>Reach the Star, a puzzle game for iOS</a>
                      <a href='https://videogameworth.com' target='_blank' rel='nofollow noreferrer'>videogameworth.com, a website collecting historical game sales data</a>
                      <a href='https://github.com/Randyhuls/MBoy' target='_blank' rel='nofollow noreferrer'>MBoy, a work in progress GameBoy Emulator [repo]</a>

                      <span id='fun-stuff'>Things that make me laugh (for the lolz):</span>
                      <div className='images'>
                        <img src='images/bad_collecting_decisions_guys_meme.jpg' alt='Bad collecting decisions guys meme'/>
                        <img src='images/programmer_meme_01.jpg' alt='Programmer meme'/>
                        <img src='images/programmer_meme_02.jpg' alt='Programmer meme'/>
                        <img src='images/programmer_meme_03.jpg' alt='Programmer meme'/>
                      </div>
                    </>
                  )
                }
              ]}
            />

            <div className='bottom-btn-container'>
              <h4>You laughed, didn't you?</h4>
              <button onClick={() => { window.scrollTo({ top: 0, behavior: 'smooth' }) }}>Back to top!</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { App }
