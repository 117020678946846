import PropTypes from 'prop-types'
import './list.style.scss'

const getFullDate = (date) => date?.toLocaleString('default', { month: 'long', year: 'numeric' }) || ''

const List = ({ label, items, pageBreak }) => {
  return (
    <div className='__COMPONENT__list' style={{ breakAfter: pageBreak ? 'page' : 'initial' }}>
      <div className='container'>
        <h3>{label}</h3>        
        <hr/>

        <ul>
          {items.map(({ label, text, children, fromDate, toDate }, i) => {
            return (
              <li key={i}>
                {label ? 
                  <header>
                  <h4>{label}</h4>                     
                    {(fromDate || toDate) && (
                      <span className='date-tag'>{fromDate ? `${getFullDate(new Date(fromDate)).toString()} -` : ''} {toDate ? getFullDate(new Date(toDate)).toString() : 'Current'}</span>
                    )}                      
                  </header>
                : null}

                {text ? <p>{text}</p> : null}
                
                <div className='formless-container'>
                  {children}
                </div>
                <hr/>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

List.propTypes = {
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    text: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ]),
    fromDate: PropTypes.instanceOf(Date),
    toDate: PropTypes.instanceOf(Date)
  })).isRequired,
  pageBreak: PropTypes.bool, // for generating pdf; we determine when there should be a page break in the pdf
}

export { List }
